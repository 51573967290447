<template>
  <div class="warp">
    <div class="user">
      <img v-if="information" :src="information.headimgUrl ? information.headimgUrl : '@/assets/images/mrtx.png'" alt="">
      <p v-if="information">{{information.nickName}}</p>
    </div>
    <div class="main">
      <div class="czb" @click="czbClick">
        <div class="ze">
          <div class="left">
            <img src="@/assets/images/qyjbzl.png" alt="">
            <span>充值总览</span>
          </div>
          <van-icon name="arrow" />
        </div>
        <p>余额：￥{{balanceTotal}}</p>
      </div>
      <div class="itemList" v-for="(item, index) in itemList" :key="index" @click="itemClick(item)">
        <div class="left">
          <img :src="item.imgUrl" alt="">
          <span>{{item.name}}</span>
        </div>
        <van-icon name="arrow" />
      </div>
    </div>
    <main-tab-bar></main-tab-bar>
  </div>
</template>

<script>
import MainTabBar from '@/components/assembly/Maintabbar.vue'
import { getInfo } from '@/api/notice.js'
import { querymoreInfo } from '@/api/order.js'
export default {
  name: 'Home',
  components: {
    MainTabBar,
  },
  data() {
    return {
      itemList: [
        {
          id: 1,
          imgUrl: require('@/assets/images/wddd.png'),
          name: '我的订单'
        },
        {
          id: 2,
          imgUrl: require('@/assets/images/sydz.png'),
          name: '清运地址'
        },
        {
          id: 3,
          imgUrl: require('@/assets/images/ccd.png'),
          name: '贮存点'
        },
        {
          id: 4,
          imgUrl: require('@/assets/images/qyjbzl.png'),
          name: '企业资料'
        },
        {
          id: 5,
          imgUrl: require('@/assets/images/qyxxzl.png'),
          name: '员工列表'
        },
        {
          id: 6,
          imgUrl: require('@/assets/images/gfm.png'),
          name: '固废码'
        },
      ],
      information: undefined,
      balanceTotal: undefined,
    }
  },
  created() {
    this.getInfo()
    this.querymoreInfo()
  },
  methods: {
    getInfo() { // 获取信息个人
      getInfo().then(res => {
        if (res.data.code === 200) {
          this.information = res.data.data.wxUser
        }
      })
    },
    itemClick(item) { // 点击根据id跳转
      if (item.id === 1) {
        this.$router.push({path: 'Myorder'})
      } else if (item.id === 2) {
        this.$router.push({path: 'Addresslist'})
      } else if (item.id === 3) {
        this.$router.push({path: 'Storagepoint'})
      } else if (item.id === 4) {
        this.$router.push({path: 'Basic'})
      } else if (item.id === 5) {
        this.$router.push({path: 'Binding'})
      } else if (item.id === 6) {
        this.$router.push({path: 'Environmentalcode'})
      }
    },
    querymoreInfo() { // 企业详细资料
      querymoreInfo().then(res => {
        if (res.data.code === 200) {
          this.balanceTotal = res.data.data.info.balanceTotal
          if (!this.balanceTotal) {
            this.balanceTotal = 0
          }
        }
      })
    },
    czbClick() {
      this.$router.push({path: 'detailed'})
    }
  }
}
</script>
<style lang="scss" scoped>
.warp {
  .user {
    display: flex;
    align-items: center;
    padding: 20px 50px;
    background-color: #fff;
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 15px;
    }
    p {
      font-size: 18px;
      font-weight: 600;
    }
  }
  .main {
    background-color: #fff;
    margin-top: 10px;
    padding: 10px 20px;
    .itemList {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;
      .left {
        display: flex;
        align-items: center;
        img {
          width: 25px;
          margin-right: 15px;
        }
      }
    }
    .czb {
      border: 1px solid #f6f6f6;
      padding: 5px;
      .ze {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 7px;
        .left {
          display: flex;
          align-items: center;
          img {
            width: 25px;
            margin-right: 15px;
          }
        }
      }
      p {
        margin-left: 40px;
      }
    }
  }
}
</style>